<template>
  <div class="page">
    <div class="pageMain">
      <div class="tableTopBtn">
        <el-button type="primary" @click="over()" :loading="loading">保存</el-button>
      </div>
      <el-form :model="addEditForm" ref="addEditForm" class="orderTable" :rules="addEditRules" :inline="true" label-width="120px">
        <el-form-item label="选择仓库" prop="warehouseId">
          <el-select v-model="addEditForm.warehouseId" filterable style="width: 300px" placeholder="可搜索选项" @change="changeWarehouse">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择商品" prop="goodsId">
          <el-select v-model="addEditForm.goodsId" clearable filterable style="width: 300px" placeholder="可搜索选项" @change="changeGood">
            <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择用户" prop="userId">
          <el-select v-model="addEditForm.userId" multiple filterable style="width: 300px" placeholder="可搜索选项" @change="changeUser">
            <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" style="width:100%">
          <el-table :data="addOrderList" style="width: 100%">
            <el-table-column label="用户">
              <template slot-scope="scope">
                <el-select v-model="scope.row.userId" filterable placeholder="可搜索选项" @change="changeItemUser(scope,$event)">
                  <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username" :value="item.id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="商品" min-width="150px">
              <template slot-scope="scope">
                <el-select v-model="scope.row.id" filterable placeholder="可搜索选项" @change="changeItemGood(scope,$event)" style="width:300px">
                  <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品数量">
              <template slot-scope="scope">
                <el-popover :ref="'popover'+scope.$index" placement="bottom" width="200" trigger="focus">
                  <div v-if="scope.row.id">
                    <p>销售库存：{{goodsNum.saleSum}}</p>
                    <p>实际库存：{{goodsNum.num}}</p>
                    <p>库存总量：{{goodsNum.saleTotalNum}}</p>
                  </div>
                  <div v-else style="width:100%;text-align:center">未选择商品</div>
                </el-popover>
                <el-input type="text" v-popover="'popover'+scope.$index" @focus="numFocus(scope)" v-model="scope.row.num" placeholder="请输入商品数量" @input="changeNum(scope,$event)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="单价(元)">
              <template slot-scope="scope">
                <el-input type="text" :disabled="true" v-model="scope.row.price" @input="changeNum(scope, $event)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="总价(元)"></el-table-column>
            <el-table-column label="活动规则">
              <template slot-scope="scope">
                <div v-if="scope.row.isActivity == 1 && scope.row.goodsMallActivityRuleList && scope.row.goodsMallActivityRuleList.length > 0">
                  <div v-if="scope.row.goodsMallActivityRuleList[0].type==0">
                    <p v-for="(sItem, standI) in scope.row.goodsMallActivityRuleList" :key="standI">{{ '满' + sItem.threshold + '份，单价 ' + sItem.discounts + '元' }}</p>
                  </div>
                  <div v-if="scope.row.goodsMallActivityRuleList[0].type==1">
                    <p v-for="(sItem, standI) in scope.row.goodsMallActivityRuleList" :key="standI">{{ '满' + sItem.threshold + '份，赠送 ' + sItem.discounts + '份'+ sItem.giftName }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="remark" label="备注">
            </el-table-column> -->
            <el-table-column prop="name" label="是否为预售单">
              <template slot-scope="scope">
                <el-switch style="display: block" v-model="scope.row.isPresell" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" active-text="是" inactive-text="否">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="是否为现称单">
              <template slot-scope="scope">
                <el-switch style="display: block" v-model="scope.row.isCurrentWeighing" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" active-text="是" inactive-text="否">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="操作">
              <template slot-scope="scope">
                <el-button size="medium" type="primary" class="el-icon-plus" @click="chengeOrderItem(scope,1)"></el-button>
                <el-button size="medium" type="danger" class="el-icon-minus" @click="chengeOrderItem(scope,2)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
// 解决js运算精度丢失问题
import * as math from "mathjs"
export default {
  name: 'add-create',
  data() {
    return {
      goodsNum: {},
      loading: false,
      userAdcode: '',//仓库adcode
      warehouseList: [],//仓库列表
      userList: [],//人员列表
      selectGoods: [],//商品列表
      selectGoodsInfo: {},//选择的商品信息
      addEditForm: {
        warehouseId: "",//仓库id
        adcode: "",
        goodsId: "",
        userId: []
      },
      // 批量添加数组
      addOrderList: [],
      addEditRules: {
        warehouseId: [{ required: true, message: "请选择仓库", trigger: "change" }],
        id: [{ required: true, message: "请选择销售商品", trigger: "change" }],
        userId: [{ required: true, message: "请选择用户", trigger: "change" }],
        num: [{ required: true, message: "请输入商品数量", trigger: "blur" }],
      },
      saleGoodsInfo: [],
    }
  },
  mounted() {
    this.getWarehouseList()
  },
  methods: {
    //数量输入框 获取焦点
    numFocus(scope) {
      let { id } = scope.row
      let warehouseId = this.addEditForm.warehouseId
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsMall/getGoodsNum", {
          params: {
            goodsId: id, warehouseId
          }
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsNum = res.data.data;
          }
        });
    },
    changeNum(scope, e, type) {
      console.log(scope.row)
      // type = 'buy' 表示已经买过 直接带入数量
      // if(!type){
      //   scope.row.num = this.$cjs.getNum(e)
      // }

      // this.addOrderList[scope.$index].num = this.$cjs.getNum(e)
      // console.log(this.addOrderList[scope.$index].num)
      // if (!Number(e)) {
      //   this.$message({
      //     message: '请输入正确的数量',
      //     type: 'warning'
      //   })
      //   scope.row.num = ''
      //   return
      // } else 
      if (isNaN(e) == true || Number(e) < 0) {
        this.$message({
          message: '请输入正确的数量',
          type: 'warning'
        })
        scope.row.num = ''
        return
      }
      //如果用户是批发商 直接取批发价
      if (scope.row.level == 2) {
        let value = math.multiply(scope.row.wholesalePrice, e)
        // scope.row.totalPrice = Number(value).toFixed(2)
        scope.row.totalPrice = this.$cjs.preciseNum(value, 2)
      } else {
        if (scope.row.isActivity == 1 && scope.row.goodsMallActivityRuleList.length > 0 && scope.row.goodsMallActivityRuleList[0].type == 0) {
          let nums = []
          scope.row.goodsMallActivityRuleList.forEach((item, index) => {
            if (e >= Number(item.threshold)) {
              scope.row.price = Number(item.discounts)
              let value = math.multiply(item.discounts, e)
              // scope.row.totalPrice = Number(value).toFixed(2)
              scope.row.totalPrice = this.$cjs.preciseNum(value, 2)
            } else {
              nums.push(index)
            }
          })
          if (nums.length == scope.row.goodsMallActivityRuleList.length) {
            scope.row.price = scope.row.oldPrice
            let value = math.multiply(scope.row.oldPrice, e)
            // scope.row.totalPrice = Number(value).toFixed(2)
            scope.row.totalPrice = this.$cjs.preciseNum(value, 2)
          }
        } else {
          let value = math.multiply(scope.row.oldPrice, e)
          // scope.row.totalPrice = Number(value).toFixed(2)
          scope.row.totalPrice = this.$cjs.preciseNum(value, 2)
        }
      }

    },
    //查询订单有没有
    async checkUserOrder(goodsId, userId) {
      let _this = this;
      return new Promise((resolve, reject) => {
        _this.$axios
          .get(_this.$axios.defaults.basePath + "/saleChildOrder/checkUserOrder", {
            params: {
              goodsId, userId
            }
          })
          .then((res) => {
            resolve(res.data)
          }).catch(reject);;
      });
    },
    over() {
      let _this = this
      for (let i in this.addOrderList) {
        if (!this.addOrderList[i].id) {
          _this.$message({
            message: "请检查商品是否全部选择",
            type: "warning",
          });
          return
        }
        if (!this.addOrderList[i].num) {
          _this.$message({
            message: "请检查商品数量是否全部填写",
            type: "warning",
          });
          return
        }
      }
      for (let i in this.addOrderList) {
        if (this.addOrderList[i].isCurrentWeighing == 1) {
          this.addOrderList[i].remark = this.addOrderList[i].num
        }
      }
      _this.loading = true
      _this.$axios({
        url: _this.$axios.defaults.basePath + "/saleChildOrder/add",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          orderFormList: _this.addOrderList,
          orderSource: 1
        },
      }).then((res) => {
        _this.loading = false
        if (res.data.errcode == 0) {
          _this.addEditForm.userId = []
          _this.addOrderList = []
          // _this.$emit("addOver", 'ok')
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.$router.push({ name: 'saleOrder' })
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    //表格选商品
    async changeItemGood(scope, e) {
      console.log(scope)
      var obj = this.selectGoods.find(function (obj) {
        return obj.id === e
      })
      var price = scope.row.level == 2 ? obj.wholesalePrice : obj.salePrice
      if (scope.row.level == 2 && !price) {
        this.$message({
          message: '当前用户等级为批发商，该商品暂未设置批发价。',
          type: 'warning'
        })
      }
      scope.row.wholesalePrice = obj.wholesalePrice
      scope.row.isPresell = obj.isPresell
      scope.row.isCurrentWeighing = obj.isCurrentWeighing
      scope.row.price = price
      scope.row.oldPrice = price
      scope.row.isActivity = obj.isActivity
      scope.row.totalPrice = ''
      scope.row.goodsMallActivityRuleList = scope.row.level == 2 ? [] : obj.goodsMallActivityRuleList
      //判断商品有没有买过 买过把数量改了
      if (scope.row.id) {
        let result = await this.checkUserOrder(scope.row.id, scope.row.userId)
        if (result.errcode == 0) {
          scope.row.num = result.data.num
          scope.row.isPresell = String(result.data.isPresell)
          scope.row.isCurrentWeighing = String(result.data.isCurrentWeighing)
          // scope.row.level = result.data.level
          // scope.row.wholesalePrice = result.data.wholesalePrice
          // var price = result.data.level == 2 ? result.data.wholesalePrice : result.data.salePrice
          // scope.row.oldPrice = price
          // scope.row.price = price
          this.changeNum(scope, Number(result.data.num), 'buy')
        } else {
          scope.row.num = ''
          // this.changeNum(scope, 1)
        }
      }

    },
    //表格选用户
    async changeItemUser(scope, e) {
      let arr = this.addOrderList.filter((item) => !this.addEditForm.userId.some((item2) => item2 == item.userId))
      let arr2 = this.addEditForm.userId.filter((item) => !this.addOrderList.some((item2) => item2.userId == item))
      //删除多选框的
      for (let i in this.addEditForm.userId) {
        for (let j in arr2) {
          if (this.addEditForm.userId[i] == arr2[j]) {
            this.addEditForm.userId.splice(i, 1)
          }
        }
      }
      //往多选框里添加
      for (let i in arr) {
        this.addEditForm.userId.push(arr[i].userId)
      }
      //判断商品有没有买过 买过把数量改了
      if (scope.row.id) {
        let result = await this.checkUserOrder(scope.row.id, scope.row.userId)
        if (result.errcode == 0) {
          scope.row.num = result.data.num
          scope.row.isPresell = String(result.data.isPresell)
          scope.row.isCurrentWeighing = String(result.data.isCurrentWeighing)
          // scope.row.level = result.data.level
          // scope.row.wholesalePrice = result.data.wholesalePrice
          // var price = result.data.level == 2 ? result.data.wholesalePrice : result.data.salePrice
          // scope.row.oldPrice = price
          // scope.row.price = price
        }
      }
      var userItem = this.userList.find(function (obj) {
        return obj.id === e
      })
      //如果是批发商 取批发商的价格
      var price = userItem.level == 2 ? scope.row.wholesalePrice : scope.row.oldPrice
      if (userItem.level == 2 && !price) {
        this.$message({
          message: '当前用户等级为批发商，该商品暂未设置批发价。',
          type: 'warning'
        })
      }
      scope.row.price = price
      // if (scope.row.num) scope.row.totalPrice = (Number(scope.row.num) * Number(scope.row.price)).toFixed(2)
      let value = Number(scope.row.num) * Number(scope.row.price)
      if (scope.row.num) scope.row.totalPrice = this.$cjs.preciseNum(value, 2)

      //如果选的用户是批发商 并且选择商品的情况下，把活动规则数组清空
      if (userItem.level == 2 && scope.row.id) scope.row.goodsMallActivityRuleList = []
    },
    //表格加减
    async chengeOrderItem(scope, flag) { //1 新增 2 删除
      if (flag == 1) {
        let obj = {
          id: "",
          num: "",
          userId: scope.row.userId,
          level: scope.row.level,
          warehouseId: this.addEditForm.warehouseId,
          // isPresell: this.selectGoodsInfo ? this.selectGoodsInfo.isPresell : "0",//是否为预售单 0为否 1为是
          // isCurrentWeighing: this.selectGoodsInfo ? this.selectGoodsInfo.isCurrentWeighing : "0",//是否为现称单 0为否 1为是
        }
        //判断商品有没有买过 买过把数量改了
        // if (scope.row.id) {
        //   let result = await this.checkUserOrder(scope.row.id, scope.row.userId)
        //   if (result.errcode == 0) {
        //     obj.num = result.data.num
        //   }
        // }
        this.addOrderList.splice(scope.$index + 1, 0, obj)
      } else {
        this.addOrderList.splice(scope.$index, 1)
        let num = 0
        for (let i in this.addOrderList) {
          if (this.addOrderList[i].userId == scope.row.userId) {
            num += 1
          }
        }
        if (num == 0) {
          this.addEditForm.userId = this.addEditForm.userId.filter(item => item != scope.row.userId)
        }

      }
    },
    // 选择用户
    async changeUser(e) {
      let addOrderList = []
      // console.log(e, this.addOrderList, this.selectGoodsInfo)
      if (!this.addOrderList.length) {
        for (let i in e) {
          var userItem = this.userList.find(function (obj) {
            return obj.id === e[i]
          })
          let obj = {};
          if (Object.keys(this.selectGoodsInfo).length != 0) {
            var price = userItem.level == 2 ? this.selectGoodsInfo.wholesalePrice : this.selectGoodsInfo.salePrice
            console.log(price)
            if (userItem.level == 2 && !price) {
              this.$message({
                message: '当前用户等级为批发商，该商品暂未设置批发价。',
                type: 'warning'
              })
            }
            obj = {
              id: this.addEditForm.goodsId,
              // num: this.selectGoodsInfo.minimum || '',
              num: '',
              userId: e[i],
              level: userItem.level,
              oldPrice: price,
              price: price,
              wholesalePrice: this.selectGoodsInfo.wholesalePrice,
              isActivity: this.selectGoodsInfo.isActivity || '',
              warehouseId: this.addEditForm.warehouseId,
              goodsMallActivityRuleList: userItem.level == 2 ? [] : this.selectGoodsInfo.goodsMallActivityRuleList || [],
              isPresell: String(this.selectGoodsInfo.isPresell) || "0",
              isCurrentWeighing: String(this.selectGoodsInfo.isCurrentWeighing) || "0",
            }
          } else {
            obj = {
              id: this.addEditForm.goodsId,
              // num: this.selectGoodsInfo.minimum || '',
              num: '',
              userId: e[i],
              level: userItem.level,
              oldPrice: 0,
              price: 0,
              wholesalePrice: 0,
              isActivity: '',
              warehouseId: this.addEditForm.warehouseId,
              goodsMallActivityRuleList: [],
              isPresell: "0",
              isCurrentWeighing: "0",
            }
          }

          //判断商品有没有买过 买过把数量改了
          if (this.addEditForm.goodsId) {
            let result = await this.checkUserOrder(this.addEditForm.goodsId, e[i])
            if (result.errcode == 0) {
              obj.num = result.data.num
              obj.isPresell = String(result.data.isPresell)
              obj.isCurrentWeighing = String(result.data.isCurrentWeighing)
              obj.isActivity = result.data.isActivity
              obj.level = result.data.level
              obj.wholesalePrice = result.data.wholesalePrice
              var price = result.data.level == 2 ? result.data.wholesalePrice : result.data.salePrice
              obj.oldPrice = price
              obj.price = price
              let data = {
                row: obj
              }
              this.changeNum(data, Number(result.data.num), 'buy')
            }
            addOrderList.push(obj)
          } else {
            obj.isPresell = this.selectGoodsInfo ? String(this.selectGoodsInfo.isPresell) : '0'
            obj.isCurrentWeighing = this.selectGoodsInfo ? String(this.selectGoodsInfo.isCurrentWeighing) : '0'
            addOrderList.push(obj)
          }

        }
        this.addOrderList = addOrderList
      } else {
        //过滤表格里选择的，多选框没选择的  把过滤的数据从表格里删除
        let addOrderListNo = this.addOrderList.filter((item) => !e.some((item2) => item2 === item.userId));
        for (let i in this.addOrderList) {
          for (let j in addOrderListNo) {
            if (this.addOrderList[i].userId == addOrderListNo[j].userId) {
              this.addOrderList.splice(i, 1)
            }
          }
        }
        //过滤多选框里选择的， 表格里没有的  把过滤的数据添加到表格里
        let no = e.filter((item) => !this.addOrderList.some((item2) => item2.userId === item));
        for (let i in no) {
          var userItem = this.userList.find(function (obj) {
            return obj.id === no[i]
          })
          let obj = {};
          if (Object.keys(this.selectGoodsInfo).length != 0) {
            var price = userItem.level == 2 ? this.selectGoodsInfo.wholesalePrice : this.selectGoodsInfo.salePrice
            if (userItem.level == 2 && !price) {
              this.$message({
                message: '当前用户等级为批发商，该商品暂未设置批发价。',
                type: 'warning'
              })
            }
            obj = {
              id: this.addEditForm.goodsId,
              // num: this.selectGoodsInfo.minimum || '',
              num: '',
              userId: no[i],
              level: userItem.level,
              oldPrice: price || 0,
              price: price || 0,
              wholesalePrice: this.selectGoodsInfo.wholesalePrice || 0,
              isActivity: this.selectGoodsInfo.isActivity || '',
              warehouseId: this.addEditForm.warehouseId,
              goodsMallActivityRuleList: userItem.level == 2 ? [] : this.selectGoodsInfo.goodsMallActivityRuleList || [],
              isPresell: String(this.selectGoodsInfo.isPresell) || "0",
              isCurrentWeighing: String(this.selectGoodsInfo.isCurrentWeighing) || "0",
            }
          } else {
            obj = {
              id: this.addEditForm.goodsId,
              // num: this.selectGoodsInfo.minimum || '',
              num: '',
              userId: no[i],
              level: userItem.level,
              oldPrice: 0,
              price: 0,
              wholesalePrice: 0,
              isActivity: '',
              warehouseId: this.addEditForm.warehouseId,
              goodsMallActivityRuleList: [],
              isPresell: "0",
              isCurrentWeighing: "0",
            }
          }

          if (this.addEditForm.goodsId) {
            let result = await this.checkUserOrder(this.addEditForm.goodsId, no[i])
            if (result.errcode == 0) {
              obj.num = result.data.num
            }
          }
          this.addOrderList.push(obj)
        }
      }
    },
    changeGood(e) {
      if (!e) {
        this.selectGoodsInfo = {}
      } else {
        let arr = this.selectGoods.filter(item => item.id === e)
        this.selectGoodsInfo = arr[0]
      }

    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.status == 200) {
            _this.warehouseList = res.data.data;
            _this.addEditForm.adcode = _this.warehouseList[0].adcode
            _this.addEditForm.warehouseId = _this.warehouseList[0].id
            _this.getListByUser()
            _this.getUserList()
          }
        });
    },
    // 获取下拉商品
    getListByUser() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleChildOrder/getGoodsMallList", {
          params: {
            // adcode: _this.addEditForm.adcode,
            warehouseId: _this.addEditForm.warehouseId,
          },
        })
        .then((res) => {

          if (res.data.errcode == 0) {
            let list = JSON.parse(JSON.stringify(res.data.data))
            console.log(res.data.data)
            list.forEach(item => {
              item.name = `${item.name}（${item.standard}）`
              item.isPresell = item.isPresell == null ? '0' : String(item.isPresell)
              item.isCurrentWeighing = item.isCurrentWeighing == null ? '0' : String(item.isCurrentWeighing)
              if (item.id == '1684753798252908546') {
                console.log(item)
              }
            });
            _this.$nextTick(() => {
              _this.selectGoods = list;
            })
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: 'warning'
            });
          }
        });
    },
    // 获取用户下拉列表
    getUserList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/getUserInUse", {
          params: {
            warehouseId: _this.addEditForm.warehouseId
          }
        })
        .then((res) => {
          if (res.status == 200) {
            res.data.data.forEach(item => {
              item.wechat = `${item.wechat}（${item.shopName}）`
            })
            _this.userList = res.data.data;
          }
        });
    },
    // 选择仓库
    changeWarehouse(e) {
      for (let i in this.warehouseList) {
        if (this.warehouseList[i].id == e) {
          this.addEditForm.adcode = this.warehouseList[i].adcode
        }
      }
      this.addEditForm.warehouseId = e
      this.addEditForm.userId = ''
      this.addEditForm.goodsId = ''
      this.addOrderList = []
      this.getListByUser()
      this.getUserList()
    },
  }
}
</script>

<style scoped>
.tables {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tables .btns {
  height: 200px;
  display: flex;
  align-items: center;
}
.orderTable >>> .el-form-item:last-child .el-form-item__content {
  width: 100%;
}
.orderTable >>> .el-table .cell {
  overflow: initial;
}
</style>