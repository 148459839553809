<template>
  <div class="page">
    <div class="pageMain">
      <div class="postForm" v-loading="loading">
        <div class="tableTopBtn">
          <el-button 
            size="mini" 
            type="primary" 
            :loading="submitBtnLoad" 
            @click="handleSubmit('formData')" 
            :disabled="type != 2">保存
          </el-button>
          <el-button 
            size="mini" 
            type="primary" 
            :loading="submitBtnLoad" 
            :disabled="type != 1"
            @click="handleSubmit('formData')">保存并新增
          </el-button>
        </div>
        <el-form :model="formData" :rules="addPrivilege" ref="formData" class="demo-ruleForm" :inline="true">
          <el-form-item label="供应商名称：" prop="name">
            <el-input v-model="formData.name" placeholder="请输入供应商名称" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="联系人：" prop="contacts">
              <el-input v-model="formData.contacts" placeholder="请输入联系人" style="width: 300px"></el-input>
            </el-form-item>
          <el-form-item label="电话：" prop="phone">
            <el-input v-model="formData.phone" placeholder="请输入联系方式" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="地址：" prop="address">
              <el-input v-model="formData.address" placeholder="请输入地址" style="width: 300px"></el-input>
            </el-form-item>
          <el-form-item label="账户：" prop="accountNumber">
            <el-input v-model="formData.accountNumber" placeholder="请输入账户" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="开户行：" prop="bankName">
            <el-input v-model="formData.bankName" placeholder="请输入开户行" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="纳税人识别号：" prop="taxNum">
            <el-input v-model="formData.taxNum" placeholder="请输入纳税人识别号" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="税率：" prop="taxRate">
            <el-input v-model="formData.taxRate" placeholder="请输入税率" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="供应商简介：" prop="remark">
            <el-input v-model="formData.remark" placeholder="请输入供应商简介" style="width: 300px" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  export default({
    data(){
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      var validatePass = (rule, value, callback)=>{ 
        if(reg_tel.test(value) == false){
          return callback(new Error('手机号格式错误，请重新输入'));
        }else{
          callback()
        }
      };
      return{
        loading:false,
        //form表单数据
        formData: {
          accountNumber: "",
          address: "",
          bankName: "",
          contacts: "",
          name: "",
          remark: "",
          phone: "",
          taxNum: "",
          taxRate: "",
        },
        addPrivilege: {
          name: [
            {
              required: true,
              message: "请输入供应商名称",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              message: "请输入手机号",
              trigger: "blur",
            },
            {
              validator: validatePass, trigger: 'blur'
            },
          ],
          address: [
            {
              required: true,
              message: "请输入地址",
              trigger: "blur",
            },
          ],
        },
        submitBtnLoad: false,
        type:'',
        id:'',
      }
    },
    created(){
      
    },
    mounted(){
      this.type = this.$route.params.type || 1
      this.id = this.$route.params.id
      if (this.type != 1) {
        this.handleDetails()
      }
    },
    methods:{
      handleDetails(){
        let that = this
        this.loading = true
        this.$axios
        .get(this.$axios.defaults.basePath + "/supplier/details", {
          params: { id: that.id },
        })
        .then(function (res) {
          that.loading = false
          if (res.data.errcode == 0) {
            let data = res.data.data
            that.formData = data;
            that.id = data.id;
          }
        });
      },
      //添加或者编辑提交表单操作
    handleSubmit(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          if (this.type == 1) {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/supplier/add",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.$router.push({name:'supplier'})
              } else {
                _this.submitBtnLoad = false;
              }
            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/supplier/update",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.$router.push({ name: 'supplier' })
              } else {
                _this.submitBtnLoad = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    }
  })
</script>
<style>
  .postForm .el-form-item__label{
    width: 140px !important;
  }
</style>