<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="日期：">
          <el-date-picker v-model="time" :default-time="['00:00:00','23:59:59']" @change="changeTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="销售人员">
          <el-select v-model="searchForm.driverId" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in driverList" :key="index" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="销售出库单名称：">
          <el-input v-model="searchForm.lxmc" placeholder="请输入销售出库单名称"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">销售出库单列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button size="mini" type="primary" @click="exportPrintTotal">导出</el-button>

            <!-- <el-button @click="exportEvent" type="primary" class="el-button--mini">导出</el-button> -->
          </div>
          <el-table ref="multipleTable" :data="tableData" show-summary :summary-method="getSummaries" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="100">
            </el-table-column>
            <el-table-column prop="" label="序号">
              <template slot-scope="scope">
                {{scope.$index+1}}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="lxmc" label="单据状态">
            </el-table-column> -->
            <el-table-column prop="created" label="单据日期" width="200">
            </el-table-column>
            <el-table-column prop="id" label="单据编号">
              <template slot-scope="scope">
                <span class="orderId" @click="goSalesDelivery(scope.row)">{{scope.row.id}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="bz" label="客户">
              <template>
                公司经办
              </template>
            </el-table-column>
            <el-table-column prop="driverName" label="销售人员">
              <template slot-scope="scope">
                {{scope.row.driverName?scope.row.driverName:'自提'}}
              </template>
            </el-table-column>
            <el-table-column prop="totalNum" label="数量">
            </el-table-column>
            <el-table-column prop="totalPrice" label="销售金额">
              <template slot-scope="scope">
                {{ scope.row.totalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="createByName" label="制单人">
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 0" style="color: #67c23a;">正常</p>
                <p v-else-if="scope.row.status == 1" style="color: #f56c6c;">异常
                  <el-button type="danger" size="mini" @click="lookDetail(scope.row.errOrder)">查看详情</el-button></p>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="bz" label="优惠率（%）">
            </el-table-column>
            <el-table-column prop="bz" label="优惠金额">
            </el-table-column>
            <el-table-column prop="bz" label="优惠后金额">
            </el-table-column> -->
            <!-- <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="edit(scope.row.id)" v-if="updateButton == '1'">编辑</el-button>
                <el-button size="mini" type="danger" @click="del(1,scope.row.id)" v-if="delButton == '1'">删除</el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>

    </div>
    <!--新增销售出库单-->
    <el-dialog :title="alertTlt" :visible.sync="addShow">
      <div class="postForm">
        <el-form :model="formData" :inline="true" :rules="addRules" ref="formData" label-width="100px" class="demo-ruleForm text-center">
          <el-form-item label="销售出库单编码：" prop="lxbm">
            <el-input v-model="formData.lxbm" placeholder="请输入销售出库单编码" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item label="销售出库单名称：" prop="lxmc">
            <el-input v-model="formData.lxmc" placeholder="请输入销售出库单名称" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="formData.bz" placeholder="请输入备注" style="width: 300px;" type="textarea" :rows="4"></el-input>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block;text-align: center;">
            <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')">提交</el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 导出打印记录汇总表 -->
    <el-dialog title="打印记录汇总表" :visible.sync="exportPrintTotalShow" width="30%" @close="exportPrintTotalShow = false;">
      <el-form :model="exportPrintTotalForm" ref="exportPrintTotalForm" :inline="true" label-width="120px">
        <el-form-item label="选择汇总日期">
          <el-date-picker v-model="exportPrintTotalForm.totalDate" @change="changeExportPrintTotal" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-table ref="multipleTable" :data="exportSumData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="selectExportSumData">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="created" label="时间" min-width="140" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="way" label="路线" min-width="140" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div style="text-align:center;padding-top:20px">
        <el-button type="primary" :disabled="exportSumIds.length==0" @click="printExportTotal">导出</el-button>
      </div>
    </el-dialog>

    <el-dialog title="异常详情" :visible.sync="dialogErrOrder">
      <template>
          <el-table ref="multipleTable" :data="errOrder" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
              <el-table-column prop="icons" label="商品图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.icons">
                  <el-image style="width: 60px;height:60px;" :src="scope.row.icons">
                  </el-image>
                </div>
                <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="title" label="商品名称" min-width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="username" label="用户昵称" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="standard" label="规格"> </el-table-column>
            <el-table-column prop="num" label="商品数量" :filter-multiple="false" :filters="[{ text: '小于10', value: '10' }, { text: '小于20', value: '20' }]" width="90"> </el-table-column>
            <el-table-column prop="price" label="价格">
              <template slot-scope="scope">
                {{ scope.row.price }} 元
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="订单总价">
              <template slot-scope="scope">
                {{ scope.row.totalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="type" label="订单类型">
              <template slot-scope="scope">
                {{ scope.row.type == 1 ? "限时订单" : scope.row.type == 2 ? "限量订单" : scope.row.type == 3 ? "限购订单" : "热销现货" }}
              </template>
            </el-table-column>
            <el-table-column prop="type" label="是否为预售单" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.isPresell == 1 ? "是" : "否"  }}
              </template>
            </el-table-column>
            <el-table-column prop="type" label="是否为现称单" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.isCurrentWeighing == 1 ? "是" : "否"  }}
              </template>
            </el-table-column>
            <el-table-column prop="type" label="是否为赠品单" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.isGift == 1 ? "是" : "否"  }}
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 0" style="color: #409eff">
                  已下单
                </p>
                <p v-else-if="scope.row.status == 1" style="color: #e6a23c">
                  退货
                </p>
                <p v-else-if="scope.row.status == 2" style="color: #333">
                  取消
                </p>
                <p v-else-if="scope.row.status == 3" style="color: #67c23a">
                  已完成
                </p>
                <p v-else-if="scope.row.status == 4" style="color: rgb(245, 108, 108)">
                  已截单
                </p>
                <p v-else-if="scope.row.status == 5" style="color: red">
                  已出库
                </p>
                <p v-else-if="scope.row.status == 6" style="color: #67c23a">
                  已配送
                </p>
                <p v-else-if="scope.row.status == 9" style="color: red">
                  已结账
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="way" width="80" label="路线" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="created" width="160" label="下单时间">
            </el-table-column>
            <el-table-column prop="outboundDeliveryTime" width="160" label="出库时间">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 4"></p>
                <p v-else>{{ scope.row.outboundDeliveryTime }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="completeTime" width="160" label="完成时间">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 4 || scope.row.status == 5"></p>
                <p v-else>{{ scope.row.completeTime }}</p>
              </template>
            </el-table-column>
          </el-table>
        </template>
    </el-dialog>
  </div>
</template>

<script>
import * as math from "mathjs"
export default {
  data() {
    let _this = this
    return {
      exportShow: false,
      exportData: {
        type: 0,
        isQuery: 0,
      },
      driverList: [],
      dialogErrOrder:false,
      errOrder: [],
      time2: [],
      time: [],
      submitBtnLoad: false,
      addRules: {
        lxbm: [
          { required: true, message: '请输入销售出库单编码', trigger: 'blur' }
        ],
        lxmc: [
          { required: true, message: '请输入销售出库单名称', trigger: 'blur' }
        ],
      },
      formData: {
        lxbm: "",//销售出库单编码,
        lxmc: "",//销售出库单名称,
        bz: "",//备注
      },
      addShow: false,
      loading: true,
      alertTlt: "",
      //搜索
      searchForm: {

        startTime: "",
        endTime: "",
        driverId: "",//销售人员id（司机id
      },
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 50, 100, 200,500,1000],
      },
      multipleTable: [],
      ids: [],
      searchButton: '',
      updateButton: '',
      addButton: '',
      delButton: '',
      infoButton: '',
      warehouseList: [],
      deliveryDate: [],
      pickerOptions: {
        disabledDate(time) {
          let tem = time.getTime()
          return !_this.deliveryDate.includes(tem);
        },
      },
      exportPrintTotalShow: false,
      exportPrintTotalForm: {
        totalDate: ''
      },
      exportSumIds: [],//打印记录汇总选择的打印记录id
      exportSumData: [],//打印记录汇总
    }
  },
  created() {
    this.listEvent();
    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'tWxbzMrjwJcszJx_update') {
        this.updateButton = '1'
      } else if (item.authority == 'print_records_query') {
        this.searchButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_add') {
        this.addButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_delete') {
        this.delButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_info') {
        this.infoButton = '1'
      }
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    '$route': 'listEvent',
    addShow: {
      handler(n) {
        if (!n) {
          Object.keys(this.formData).forEach(key => { this.formData[key] = '' })
        }
      }
    }
  },
  mounted() {
    this.getDriverList()
    this.getWarehouseList()
  },
  methods: {
    printExportTotal() {
      let ids = this.exportSumIds.join(",")
      window.open(this.$axios.defaults.basePath + "/printRecords/exportSum?printRecordIds=" + ids)
    },
    //导出打印订单汇总 选择事件
    selectExportSumData(e) {
      this.exportSumIds = []
      e.forEach((item) => {
        this.exportSumIds.push(item.id);
      });
    },
    // 导出订单打印汇总表
    exportPrintTotal() {
      this.getDeliveryDate(0)
      this.exportPrintTotalShow = true;
    },
    // 获取有出库记录的日期
    getDeliveryDate(type) { // 0打印订单汇总 1 一键完成
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/printRecords/selectDeliveryDate"
          , {
            params: {
              type
            }
          })
        .then((res) => {
          if (res.data.errcode == 0) {
            let data = []
            for (let i in res.data.data) {
              let time = new Date(res.data.data[i] + ' 00:00:00')
              data.push(time.valueOf())
            }
            _this.deliveryDate = data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    changeExportPrintTotal(e) {
      this.getPrintRecords(e)
    },
    // 获取打印记录
    getPrintRecords(selectTime) {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/printRecords", {
          params: {
            selectTime
          }
        }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            if (selectTime) {
              if (res.data.data.length) {
                _this.exportSumData = res.data.data
              } else {
                _this.exportSumData = []
                _this.$message({
                  message: selectTime + "日无打印记录",
                  type: "warning",
                });
              }
            } else {
              for (let i in res.data.data) {
                res.data.data[0].id = Number(res.data.data[0].id)
                res.data.data[i].name = res.data.data[i].created + ' ' + res.data.data[i].way
              }
              _this.exportPrintForm.id = res.data.data[0].id
              _this.exportPrintData = res.data.data[0]
              _this.printRecords = res.data.data
            }

          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.status == 200) {
            _this.warehouseList = res.data.data;
          }
        });
    },
    // 导出
    exportEvent() {
      this.exportShow = true
    },
    exportSubmit() {
      let _this = this;
      this.$axios.get(_this.$axios.defaults.basePath + '/printRecords/exportSalesSummaryGoods', {
        params: _this.exportData,
        responseType: 'blob'
      }).then(function (res) {
        console.log(res)
        var eleLink = document.createElement("a");
        eleLink.download = "销售出库单.csv";
        eleLink.style.display = "none";

        var blob = new Blob([res.data]);
        eleLink.href = URL.createObjectURL(blob);
        // 触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        _this.$message({
          message: "导出成功",
          type: "success",
        });
        _this.exportShow = false;
        _this.$set(_this.exportData, 'startTime', '')
        _this.$set(_this.exportData, 'endTime', '')
        _this.$set(_this.exportData, 'warehouseId', '')
        _this.$set(_this.exportData, 'driverId', '')
        _this.$set(_this.exportData, 'type', 0)
        _this.$set(_this.exportData, 'isQuery', 0)
        // 然后移除
        document.body.removeChild(eleLink);
      })
    },
    goSalesDelivery(row) {
      this.$router.push({ name: "salesDelivery", params: row })
    },
    //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property == "totalNum" || column.property == "totalPrice") {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return Number(math.add(prev, curr)).toFixed(2);
              } else {
                return prev;
              }
            }, 0);
            // sums[index] += ' 元';
          } else {
            //   sums[index] = 'N/A';
          }
        }

      });

      return sums;
    },
    // 获取司机下拉列表
    getDriverList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driver/getDriver")
        .then((res) => {
          if (res.data.errcode == 0) {
            let obj = {
              username: "自提",
              id: "0"
            }
            res.data.data.unshift(obj)
            _this.driverList = res.data.data;
          }
        });
    },
    changeTime(e) {
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    changeTime2(e) {
      this.exportData.startTime = e[0]
      this.exportData.endTime = e[1]
    },
    add() {
      this.addShow = true
      this.alertTlt = "新增销售出库单"
    },
    //编辑
    edit(id) {
      let that = this
      this.$axios.get(this.$axios.defaults.basePath + '/tWxbzMrjwJcszJx/info', {
        params: {
          id: id
        }
      }).then(function (res) {
        if (res.data.errcode == 0) {
          that.alertTlt = "编辑销售出库单"
          that.addShow = true
          that.formData = res.data.data
        } else {
          that.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage
      _this.searchForm.size = _this.pagesData.currentRows
      this.$axios.get(_this.$axios.defaults.basePath + '/printRecords/salesDeliveryList', {
        params: _this.searchForm
      }).then(function (res) {
        if (res.data.errcode == 0) {
          for (let i in res.data.data.records) {
            res.data.data.records[i].printRecordsId = res.data.data.records[i].id
          }
          _this.loading = false;
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent()
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent()
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.listEvent()
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach(key => { this.searchForm[key] = '' })
      this.time = []
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.listEvent()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },

    lookDetail(errOrder){
      var _this = this;
      _this.dialogErrOrder = true;
      _this.errOrder = JSON.parse(errOrder);
      console.log(JSON.parse(errOrder))
    },

    handleSubmit(formData) {
      var _this = this;
      let api = ""
      if (_this.alertTlt == "编辑销售出库单") {
        api = '/tWxbzMrjwJcszJx/update'
      } else {
        api = '/tWxbzMrjwJcszJx/add'
      }

      this.$refs[formData].validate((val) => {
        if (val) {
          _this.submitBtnLoad = true
          this.$axios({
            url: _this.$axios.defaults.basePath + api,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            data: _this.formData
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.submitBtnLoad = false
              _this.$message({
                message: '操作成功',
                type: 'success'
              });
              _this.addShow = false;
              _this.formData = {};
              _this.listEvent();
            } else {
              _this.submitBtnLoad = false
              _this.$message.error(res.data.errmsg);
            }
          })
        } else {
          return false
        }

      })

    },
    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    del(flag, id) {
      var _this = this;
      let ids = ""
      if (flag == 1) {//单个删除
        ids = id
      } else {//批量删除
        if (_this.ids.length == 0) {
          _this.$message.error('请选择要删除的销售出库单');
          return false;
        } else {
          ids = _this.ids.join(",")
        }
      }
      _this.$confirm('是否确认删除该销售出库单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.$axios.get(_this.$axios.defaults.basePath + '/tWxbzMrjwJcszJx/del', {
          params: {
            id: ids
          }
        })
          .then(function (res) {
            if (res.data.errcode == 0) {
              _this.$message({
                message: '删除成功',
                type: 'success'
              });
              _this.listEvent();
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: 'error'
              });
            }
          })
      }).catch(() => {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    }
  }
}
</script>

<style  scoped>
.orderId {
  color: #409eff;
  cursor: pointer;
}
</style>