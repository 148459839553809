<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="单据日期：">
          <el-date-picker v-model="time" :default-time="['00:00:00','23:59:59']" @change="changeTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品">
          <el-select filterable v-model="goodsIds" multiple clearable :reserve-keyword="true" placeholder="请选择商品（可多选）" style="width: 250px" @change="searchGoodsMore">
            <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售人员:">
          <el-select v-model="searchForm.driverId" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in driverList" :key="index" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-checkbox style="margin-left:20px" v-model="checked" @change="changeCheckbox">计算毛利</el-checkbox>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">销售明细列表</p>
        <div class="tableTopBtn">
          <el-button size="mini" type="primary" @click="exportData" v-if="searchButton == '1'">导出</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" height="700" :data="tableData" show-summary :summary-method="getSummaries" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column prop="outboundDeliveryTime" label="销售日期" width="200">
            </el-table-column>
            <el-table-column prop="printRecordsId" label="单据编号">
              <template slot-scope="scope">
                <span class="orderId" @click="goSalesDelivery(scope.row)">{{scope.row.printRecordsId}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="driverName" label="销售人员">
              <template slot-scope="scope">
                {{scope.row.driverName?scope.row.driverName:'自提'}}
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="客户">
            </el-table-column>
            <!-- <el-table-column prop="code" label="商品编码">
            </el-table-column> -->
            <el-table-column prop="title" label="名称">
            </el-table-column>
            <el-table-column prop="categoryName" label="商品类别">
            </el-table-column>
            <el-table-column prop="standard" label="规格型号">
            </el-table-column>
            <el-table-column prop="unit" label="单位">
            </el-table-column>
            <el-table-column prop="warehouseName" label="仓库">
            </el-table-column>
            <el-table-column prop="num" label="数量">
            </el-table-column>
            <el-table-column prop="remark" label="现称个数">
            </el-table-column>
            <el-table-column prop="price" label="单价">
              <template slot-scope="scope">
                {{ scope.row.price }} 元
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="销售收入">
              <template slot-scope="scope">
                {{ scope.row.totalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="costPrice" label="单价成本" v-if="searchForm.isQuery">
              <template slot-scope="scope">
                {{ scope.row.costPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="costTotalPrice" label="销售成本" v-if="searchForm.isQuery">
              <template slot-scope="scope">
                {{ scope.row.costTotalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="saleGrossProfit" label="销售毛利" v-if="searchForm.isQuery">
              <template slot-scope="scope">
                {{ scope.row.saleGrossProfit }} 元
              </template>
            </el-table-column>
            <el-table-column prop="grossMargin" label="毛利率" v-if="searchForm.isQuery">
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            当前第 {{ pagesData.currentPage }} 页，共 {{ pagesData.pages }} 页 {{ pagesData.total }} 条
            <el-select v-model="pagesData.currentRows" @change="handleSizeChange" placeholder="请选择每页条数">
              <!-- <el-option :value="10">10条/页</el-option> -->
              <!-- <el-option :value="50">50条/页</el-option> -->
              <el-option :value="100">100条/页</el-option>
              <el-option :value="200">200条/页</el-option>
              <el-option :value="500">500条/页</el-option>
              <el-option :value="1000">1000条/页</el-option>
              <el-option :value="1500">1500条/页</el-option>
              <el-option :value="2000">2000条/页</el-option>
              <el-option :value="5000">5000条/页</el-option>
            </el-select>
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination> -->
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      driverList: [],
      goodsIds: [],
      selectGoods: [],
      time: [],
      loading: true,
      checked: false,
      //搜索
      searchForm: {
        startTime: "",
        endTime: "",
        isQuery: 0,
        driverId: ""
      },
      tableData: [],
      //分页数据
      pagesData: {
        pages: 0,
        total: 0,
        currentPage: 1,
        currentRows: 100,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      ids: [],
      searchButton: '',
      updateButton: '',
      addButton: '',
      delButton: '',
      infoButton: '',
      adcode: sessionStorage.getItem('adcode'),
      lazyFlag: true,
      // 每页条数切换标识
      sizeFlag: false,
    }
  },
  created() {

    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'tWxbzMrjwJcszJx_update') {
        this.updateButton = '1'
      } else if (item.authority == 'print_records_query') {
        this.searchButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_add') {
        this.addButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_delete') {
        this.delButton = '1'
      } else if (item.authority == 'tWxbzMrjwJcszJx_info') {
        this.infoButton = '1'
      }
    });
  },
  mounted() {
    this.getSelectGoods()
    this.getDriverList()
    let arr = Object.keys(this.$route.params)
    let params = this.$route.params
    // return
    if (arr.length) {
      if (params.flag == 0) {
        this.goodsIds.push(params.goodsId)
        this.searchForm.goodsId = params.goodsId
      } else if (params.flag == 1) {
        this.searchForm.driverId = params.driverId
      }
      let time = [params.startTime, params.endTime]
      this.time = time
      this.searchForm.startTime = params.startTime
      this.searchForm.endTime = params.endTime
    } else {
      let date = new Date();
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1);
      let day = String(date.getDate());
      let hour = String(date.getHours());
      let minutes = String(date.getMinutes());
      let seconds = String(date.getSeconds())
      month = month.length === 2 ? month : 0 + month;
      day = day.length === 2 ? day : 0 + day;
      hour = hour.length === 2 ? hour : 0 + hour;
      minutes = minutes.length === 2 ? minutes : 0 + minutes;
      seconds = seconds.length === 2 ? seconds : 0 + seconds;
      let startTime = `${year}-${month}-01 00:00:00`;
      let endTime = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
      let time = [startTime, endTime]
      this.time = time
      this.searchForm.startTime = startTime
      this.searchForm.endTime = endTime
    }
    this.listEvent();

    // 表格懒加载
    let table = this.$refs.multipleTable.$el;
    table.addEventListener('scroll', (res) => {
      // console.log(res.target.scrollHeight - 50, Number((res.target.scrollTop).toFixed(2)), res.target.clientHeight)
      // 如果是切换条数触发的滚动则不走此方法
      if (this.sizeFlag) { return }
      if (Number((res.target.scrollTop).toFixed(2)) + res.target.clientHeight > res.target.scrollHeight - 10) {
        if (this.lazyFlag == true && this.tableData.length < this.pagesData.total && this.pagesData.currentPage < this.pagesData.pages) {
          this.$set(this.pagesData, 'currentPage', this.pagesData.currentPage + 1)
          this.listEvent();
          this.lazyFlag = false
        }
      }
    }, true)
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout();
    });

  },
  methods: {
    // 获取司机下拉列表
    getDriverList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driver/getDriver")
        .then((res) => {
          if (res.data.errcode == 0) {
            let obj = {
              username: "自提",
              id: "0"
            }
            res.data.data.unshift(obj)
            _this.driverList = res.data.data;
          }
        });
    },
    //导出
    exportData() {
      let _this = this;
      let data = JSON.parse(JSON.stringify(this.searchForm))
      delete data.current
      delete data.size
      this.$axios
        .get(this.$axios.defaults.basePath + "/printRecords/exportSalesDetails", {
          params: data,
          responseType: 'blob'
        })
        .then((res) => {
          var eleLink = document.createElement("a");
          eleLink.download = "销售明细表.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          // 然后移除
          document.body.removeChild(eleLink);
        })

    },
    changeCheckbox(e) {
      this.tableData = []
      this.pagesData.currentPage = 1
      // this.pagesData.currentRows = 10
      this.searchForm.isQuery = e ? 1 : 0
      this.listEvent()
    },
    // 搜索商品多选
    searchGoodsMore(e) {
      this.searchForm.goodsId = e.join(',')
    },
    goSalesDelivery(row) {
      this.$router.push({ name: "salesDelivery", params: row })
    },
    //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let ml = null //销售毛利
      let sr = null //销售收入 
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property == "num" || column.property == "totalPrice" || column.property == "costPrice" || column.property == "costTotalPrice" || column.property == "saleGrossProfit") {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            let num = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = num.toFixed(2)
            // sums[index] += ' 元';
          } else {
            //   sums[index] = 'N/A';
          }
          if (column.property == "totalPrice") {
            sr = sums[index]
          }
          if (column.property == "saleGrossProfit") {
            ml = sums[index]
          }
        }
        if (column.property == "grossMargin") {
          sums[index] = String(((ml / sr) * 100).toFixed(2)) + '%'
        }
      });
      return sums;
    },
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = item.name + ' ' + item.standard;
            });
            _this.exportGoods = list;
            _this.selectGoods = list;
          }
        });
    },
    changeTime(e) {
      if (!e || e.length == 0) {
        this.searchForm.startTime = ""
        this.searchForm.endTime = ""
      } else {
        this.searchForm.startTime = e[0]
        this.searchForm.endTime = e[1]
      }

    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.loading = true
      // console.log(_this.pagesData.currentPage)
      _this.searchForm.current = _this.pagesData.currentPage
      _this.searchForm.size = _this.pagesData.currentRows
      this.$axios.get(_this.$axios.defaults.basePath + '/printRecords/salesDetails', {
        params: _this.searchForm
      }).then(function (res) {
        _this.loading = false;
        // console.log(res)
        if (res.data.errcode == 0) {
          // console.log(_this.tableData.length, "_this.tableData.length")
          if (_this.tableData.length == 0) {
            _this.tableData = res.data.data.records;
          } else {
            _this.tableData = _this.tableData.concat(res.data.data.records);
          }

          _this.pagesData.total = res.data.data.total;
          _this.pagesData.pages = res.data.data.pages;

          if (_this.lazyFlag == false && _this.pagesData.currentPage == _this.pagesData.pages) {
            _this.$message({
              message: '已经是最后一页了',
              type: 'info'
            })
          }
          _this.lazyFlag = true
          _this.sizeFlag = false
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.tableData = []
      this.$set(this.pagesData, 'currentRows', val)
      this.$set(this.pagesData, 'currentPage', 1)
      this.sizeFlag = true
      _this.listEvent()
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent()
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.tableData = []
      this.listEvent()
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach(key => { this.searchForm[key] = '' })
      this.time = []
      this.goodsIds = [];
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.tableData = []
      this.searchForm.isQuery = this.checked ? 1 : 0
      this.listEvent()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },
  }
}
</script>

<style  scoped>
.orderId {
  color: #409eff;
  cursor: pointer;
}
.boxMain >>> .el-table .el-table__footer-wrapper tr td .cell {
  font-weight: 600;
}
.userTable >>> .el-table {
  overflow: visible !important;
}
</style>