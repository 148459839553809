<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline">
        <el-form-item label="订单编码：">
            <el-input
            :disabled="true"
              v-model="searchForm.orderCode"
              placeholder="请输入订单编码"
            ></el-input>
          </el-form-item>
        <el-form-item label="日期：">
          <el-date-picker v-model="searchForm.created" type="datetime" :disabled="true" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="销售人员">
          <el-select v-model="searchForm.driverId" :disabled="true" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in driverList" :key="index" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">销售出库单列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="exportEvent" type="primary" class="el-button--mini">导出</el-button>
          </div>
          <el-table ref="multipleTable" :data="orderData.list" show-summary :summary-method="getSummaries" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="100">
            </el-table-column>
            <!-- <el-table-column prop="code" label="商品编码">
            </el-table-column> -->
            <el-table-column prop="title" label="商品名称">
            </el-table-column>
            <el-table-column prop="standard" label="规格" width="200">
            </el-table-column>
            <!-- <el-table-column prop="categoryName" label="商品类别">
            </el-table-column>
            <el-table-column prop="unit" label="单位">
            </el-table-column> -->
            <el-table-column prop="warehouseName" label="仓库">
            </el-table-column>
            <el-table-column prop="num" label="数量">
            </el-table-column>
            <el-table-column prop="price" label="单价">
               <template slot-scope="scope">
                  {{ scope.row.price }} 元
                </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="总价">
               <template slot-scope="scope">
                  {{ scope.row.totalPrice }} 元
                </template>
            </el-table-column>
          </el-table>
        </template>
      </div>

      <div class="userTable boxMain" style="margin-top:30px">
        <p class="boxTitle">修改订单列表</p>
        <template>
          <el-table ref="multipleTable" :data="orderData.updateSaleOrderRecords" show-summary :summary-method="getSummaries" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column prop="wechat" label="微信昵称">
            </el-table-column>
            <el-table-column prop="title" label="商品标题">
            </el-table-column>
            <el-table-column prop="standard" label="规格" width="200">
            </el-table-column>
            <el-table-column prop="price" label="单价">
               <template slot-scope="scope">
                  {{ scope.row.price }} 元
                </template>
            </el-table-column>
            <el-table-column prop="num" label="数量">
            </el-table-column>
            <el-table-column prop="totalPrice" label="总价">
               <template slot-scope="scope">
                  {{ scope.row.totalPrice }} 元
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 0" style="color: #409eff">
                  出库转已截单
                </p>
                <p v-else-if="scope.row.status == 1" style="color: #e6a23c">
                  完成转已截单
                </p>
                <p v-else-if="scope.row.status == 2" style="color: #333">
                  赠品出库转已截单
                </p>
                <p v-else-if="scope.row.status == 3" style="color: #67c23a">
                  退货
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="createByName" label="操作人">
            </el-table-column>
            <el-table-column prop="created" label="操作时间">
            </el-table-column>
          </el-table>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "salesDelivery",
  data() {
    return {
      driverList: [],
      loading: false,
      orderData: {},
      multipleTable: [],
      ids: [],
      searchForm:{
        orderCode:''
      },
      exportShow:false,
      exportData:{
        id:''
      }
    }
  },
  created() {
    if (this.$route.params && this.$route.params.orderCode) {
      this.$set(this.searchForm, 'orderCode', this.$route.params.orderCode)
    }
    if(this.$route.params.id){
      this.$set(this.exportData, 'id', this.$route.params.id)
    }
  },

  mounted() {
    this.searchForm = this.$route.params
    this.getDriverList()
    this.getSalesDelivery()
  },
  activated(){
    this.searchForm = this.$route.params
    this.getSalesDelivery()
  },
  methods: {
    exportEvent(){
      let _this = this;
      this.$axios.get(_this.$axios.defaults.basePath + '/printRecords/export', {
        params: _this.exportData,
        responseType: 'blob'
      }).then(function (res) {
        console.log(res)
        var eleLink = document.createElement("a");
        eleLink.download = "销售出库单详情.csv";
        eleLink.style.display = "none";

        var blob = new Blob([res.data]);
        eleLink.href = URL.createObjectURL(blob);
        // 触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        _this.$message({
          message: "导出成功",
          type: "success",
        });
        _this.exportData = {};
        // 然后移除
        document.body.removeChild(eleLink);
      })
    },
    // 查询销售出库单
    getSalesDelivery() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/printRecords/salesDelivery",
          {
            params: this.searchForm
          })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.orderData = res.data.data
          }
        });
    },
    //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property == "num" || column.property == "totalPrice") {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            let num = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = num.toFixed(2)
            // sums[index] += ' 元';
          } else {
            //   sums[index] = 'N/A';
          }
        }

      });

      return sums;
    },
    // 获取司机下拉列表
    getDriverList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driver/getDriver")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.driverList = res.data.data;
          }
        });
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },


  }
}
</script>

<style  scoped>
.boxMain >>> .el-table .el-table__footer-wrapper tr td .cell {
  font-weight: 600;
}
</style>