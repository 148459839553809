<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="供应商名称">
          <el-input v-model="searchForm.name" placeholder="请输入供应商名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="searchForm.contacts" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="searchForm.phone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">供应商列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="handleAdd" type="primary" class="el-button--mini" v-if="addButton == '1'"><i class="el-icon-plus"></i>添加供应商</el-button>
            <el-button size="mini" type="danger" @click="handleDel()" :disabled="ids.length == 0" v-if="delButton == '1'" class="el-icon-delete">批量删除</el-button>
          </div>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="供应商名称"> </el-table-column>
            <el-table-column prop="contacts" label="联系人"> </el-table-column>
            <el-table-column prop="accountNumber" label="账户">
            </el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column>
            <el-table-column prop="phone" label="联系方式"> </el-table-column>
            <el-table-column align="center" width="160" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'" class="el-icon-edit">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: "1",
      tableData: [],
      loading: true,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //搜索
      searchForm: {
        name: "",
        contacts: "",
        phone: "",
      },
      multipleTable: [],
      ids: [],
      id: "",
      idStr: "",
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
    };
  },

  created() {
    this.listEvent();
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "supplier_update") {
        this.updateButton = "1";
      } else if (item.authority == "supplier_query") {
        this.searchButton = "1";
      } else if (item.authority == "supplier_add") {
        this.addButton = "1";
      } else if (item.authority == "supplier_delete") {
        this.delButton = "1";
      }
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
  },
  methods: {
    listEvent() {
      //获取列表数据
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/supplier", {
          params: {
            name: _this.searchForm.name,
            contacts: _this.searchForm.contacts,
            phone: _this.searchForm.phone,
            current: 1,
            size: _this.pagesData.currentRows,
          },
        })
        .then(function (res) {
          _this.loading = false;
          if (res.data.errcode == 0) {
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.pagesEvent(_this.pagesData.currentPage, val);
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.pagesEvent(val, _this.pagesData.currentRows);
    },
    //分页请求数据方法
    pagesEvent(page, rows) {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/supplier", {
          params: {
            current: page,
            size: rows,
          },
        })
        .then(function (res) {
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        });
    },

    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },

    //点击添加权限按钮事件
    handleAdd() {
      this.$router.push({name:'supplierForm',params:{type:1}})
    },
    //勾选列表触发事件
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    //点击编辑按钮操作
    handleEdit(value) {
      this.$router.push({name:'supplierForm',params:{type:2,id:value.id}})
    },
    

    //删除操作
    handleDel() {
      var _this = this;
      if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的内容");
        return false;
      } else {
        _this
          .$confirm("此操作将永久删除该供应商, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            var _this = this;
            _this.$axios
              .get(_this.$axios.defaults.basePath + "/supplier/delete", {
                params: {
                  ids: _this.ids.join(","),
                },
              })
              .then(function (res) {
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  _this.listEvent();
                }
              });
          })
          .catch(() => {
            _this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
  },
};
</script>

<style scoped>
/* .postForm .el-form-item__label {
  width: 100px !important;
} */
</style>
